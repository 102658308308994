/*
 * Copyright 2021 the original author or authors.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

#banner-container {
  height: var(--layout-banner-height);
  overflow: hidden;
  border-bottom: 1px solid var(--layout-border-color);
}

#banner {
  height: 100%;
  background: no-repeat top var(--layout-banner-logo-offset) left
    var(--layout-banner-logo-offset) / auto var(--layout-banner-logo-height);
  background-image: url("../img/banner-logo.svg");
}

#doc {
  overflow: auto;
}

.contained {
  max-width: var(--layout-max-width);
  margin: 0 auto;
}

div#switch-theme,
#switch-theme label {
  display: none;
}

html.js div#switch-theme {
  display: block;
  float: right;
  margin: 8px 6px 0 0;
}

#switch-theme input {
  appearance: none;
  position: relative;
  width: 40px;
  height: 22px;
  filter: var(--layout-switchtheme-invert-filter);
  background: no-repeat url("../img/octicons-16.svg#view-sun") 90% 50% / 16px
      16px,
    no-repeat url("../img/octicons-16.svg#view-moon") 10% 50% / 16px 16px;
  background-color: var(--layout-switchtheme-background-color);
  border-radius: 25px;
  outline: none;
}

#switch-theme input::before {
  filter: var(--layout-switchtheme-invert-filter);
  content: "";
  position: absolute;
  left: 2px;
  top: 2px;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background-color: var(--layout-switchtheme-button-color);
  transition: transform 200ms;
}

#switch-theme:hover input::before {
  background-color: var(--layout-switchtheme-button-hover-color);
}

#switch-theme input:checked::before {
  transform: translateX(18px);
}
