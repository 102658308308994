/*
 * Copyright 2021 the original author or authors.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/* Block Switching */

.hidden {
  display: none;
}

.doc .tabs {
  font-weight: bold;
  font-size: calc(12 / var(--pixel-to-rem));
  border-style: none;
  display: inline-block;
  position: relative;
  bottom: 0;
  margin-top: 0.5rem;
  margin-bottom: calc(2 / var(--pixel-to-rem));
}

.doc .tab:not(:first-child) {
  border: 1px solid var(--tabs-border-color);
}

.doc .tab {
  padding: 0.3rem 0.6rem;
  background-color: var(--tabs-background-color);
  color: var(--tabs-font-color);
  display: inline-block;
  cursor: pointer;
  border: 1px solid var(--tabs-border-color);
  margin-bottom: calc(2 / var(--pixel-to-rem));
  border-radius: 0;
  transition: background-color 200ms;
}

.doc .tab:hover {
  color: var(--tabs-hover-font-color);
  background-color: var(--tabs-hover-background);
  text-decoration: underline;
}

.doc .tab.selected {
  background-color: var(--tabs-selected-background-color);
  border-color: var(--tabs-selected-background-color);
  color: var(--tabs-selected-font-color);
}

.doc .tab.selected:hover {
  color: var(--tabs-selected-font-color);
  text-decoration: none;
}

.doc div.openblock.tabs-content > .content {
  padding: 1rem;
  background-color: var(--tabs-group-background-color);
}
