html.dark-theme {
  /* General */
  --font-weight: 300;
  --body-background-color: #1b1f23;
  --panel-background-color: #262a2d;
  --panel-group-background-color: #303741;
  --panel-border-color: #2c3135;
  --color-accent-1: #272c33;
  --color-accent-1-invert: #d8d3cc;
  --color-accent-2: #2d333a;
  --color-accent-3: #6db33f;
  --body-font-color: #bbbcbe;
  --body-font-light-color: #abacaf;
  --body-font-dark-color: #cecfd1;
  --link-font-color: #086dc3;
  --hover-link-font-color: #107ddd;
  --scrollbar-thumb-color: #5f5f5f;
  --mark-background-color: #2eca12;
  --selected-background-color: #8d8d8d;

  /* Layout */
  --layout-switchtheme-invert-filter: none;
  --layout-switchtheme-background-color: var(--selected-background-color);

  /* Asciidoctor */
  --asciidoctor-code-background: rgba(177, 209, 241, 0.15);
  --asciidoctor-code-data-lang-color: #6e6e6e;
  --asciidoctor-admonition-font-color: #f0f0f0;
  --asciidoctor-admonition-caution-background: #603668;
  --asciidoctor-admonition-important-background: #924040;
  --asciidoctor-admonition-note-background: #355463;
  --asciidoctor-admonition-tip-background: #4d6340;
  --asciidoctor-admonition-warning-background: #967745;
  --asciidoctor-footer-font-color: #5e5e5e;

  /* Highlight JS (colors based on https://github.com/primer/github-syntax-dark) */
  --highlight-background-color: var(--asciidoctor-pre-background);
  --highlight-font-color: #f6f8fa;
  --highlight-keyword-font-color: #ea4a5a;
  --highlight-comment-font-color: #959da5;
  --highlight-string-font-color: #79b8ff;
  --highlight-meta-font-color: #959da5;
  --highlight-constant-font-color: #79b8ff;
  --highlight-variable-font-color: #c8e1ff;
  --highlight-tag-font-color: #7bcc72;
  --highlight-tag-attribute-font-color: #b392f0;
  --highlight-type-font-color: #b392f0;
  --highlight-link-font-color: #1565c0;
  --highlight-addition-font-color: #7bcc72;
  --highlight-deletion-font-color: #f6f8fa;
  --highlight-regex-font-color: #79b8ff;

  /* TOC */
  --toc-back-to-index-filter: invert();
  --toc-bar-button-filter: invert();

  /* Code Tools */
  --codetools-button-filter: invert();
  --codetools-button-active-filter: none;
  --codetools-hover-background-color: var(--color-accent-1-invert);
  --codetools-border-color: rgba(255, 255, 255, 0.274);
  --codetools-divider-color: rgba(44, 44, 44, 0.274);
}
