/*
 * Copyright 2021 the original author or authors.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

html {
  height: 100%;
  font-size: var(--html-font-size);
  scroll-behavior: smooth;
  min-width: 340px;
}

body {
  margin: 0;
  overflow-wrap: anywhere;
  overscroll-behavior: none;
  font-family: var(--font-family);
  font-weight: var(--font-weight);
  color: var(--body-font-color);
  background-color: var(--body-background-color);
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active {
  background-color: none;
}

code,
kbd,
pre {
  font-family: var(--monospace-font-family);
}

@supports (scrollbar-width: thin) {
  body * {
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-thumb-color) transparent;
  }
}

table {
  border-collapse: collapse;
  word-wrap: normal;
}

mark {
  background: var(--mark-background-color);
}
